import type {
  WidgetManifest,
  GfppDesktopConfig,
} from '@wix/platform-editor-sdk';
import { postPageWidgetDesign } from './blocks-post-page-manifest-widget-design';

export const buildBlocksPostPageManifest = (
  dataFromBlocks: WidgetManifest,
): WidgetManifest => ({
  ...dataFromBlocks,
  default: {
    ...dataFromBlocks.default,
    behavior: {
      removable: false,
      duplicatable: false,
      toggleShowOnAllPagesEnabled: false,
      essential: {
        enabled: false,
      },
    },
    gfpp: {
      ...dataFromBlocks.default.gfpp,
      desktop: {
        ...(dataFromBlocks.default.gfpp?.desktop ?? {}),
        iconButtons: (dataFromBlocks.default.gfpp?.desktop as GfppDesktopConfig)
          ?.iconButtons,
        widgetDesign: postPageWidgetDesign,
        widgetPresets: {
          presets: [
            {
              id: 'variants-ln2sz6bn1',
              src: '',
            },
          ],
        },
      },
    },
  },
});
