import postPageCompJson from '../components/Post Page/.component.json';
import relatedPostsJson from '../components/RelatedPosts/.component.json';
import { blogAppDefId } from '../external/common/constants/apps';
import { TPA_PAGE_ID_POST } from '../external/common/constants/tpa-pages';
import concurrentEditing from '../external/common/services/concurrent-editing';
import { EditorAppContext } from '../types/editor-app-context.type';
import { addBlocksWidget } from './add-blocks-widget';

export const installPostPage = async (context: EditorAppContext) => {
  console.log('[Blog] Installing Blocks Post Page...');

  await concurrentEditing.withApproval(async () => {
    try {
      const postPageRef = await addBlocksWidget({
        context,
        widgetName: 'postPage',
      });
      await removeOldPostWidget();

      console.log('[Blog]', { postPageRef });
      console.log('[Blog] Installing Blocks Post Page... Adding plugins...');

      await addWidgetToPostPage(relatedPostsJson.id);

      console.log('[Blog] Installing Blocks Post Page... Done!');
    } catch (err) {
      console.error('[Blog] Installing Blocks Post Page... Failed!', err);
      throw err;
    }
  });

  async function addWidgetToPostPage(widgetId: string) {
    await context.sdk.tpa.widgetPlugins.addWidgetPlugin(context.appToken, {
      widgetId: postPageCompJson.id,
      /** Matches the "id" of the SlotPlaceholder element in Blocks Editor */
      slotRole: 'slot1',
      widgetPluginPointer: {
        appDefinitionId: blogAppDefId,
        widgetId,
      },
    });
  }

  async function removeOldPostWidget() {
    const pageRef = await context.sdk.tpa.getPageRefByTPAPageId(
      context.appToken,
      { tpaPageId: TPA_PAGE_ID_POST },
    );
    const children = await context.sdk.components.getChildren(
      context.appToken,
      { componentRef: pageRef },
    );

    if (children.length) {
      await context.sdk.components?.remove(context.appToken, {
        componentRef: children[0],
      });
    }
  }
};
