import {
  ElementsPanelParams,
  openElementsPanel,
} from '../../blocks/common/elements-panel';
import { EditorAppContext } from '../../types/editor-app-context.type';
import { POST_PAGE_SELECTORS } from './post-page-constants';

export const openPostPageElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) =>
  openElementsPanel(context, {
    widgetRef,
    categoriesData: [{ id: CATEGORY_IDS.mainElements, title: 'Main Elements' }],
    // prettier-ignore
    elementsData: [
      { label: COMPS_DATA.postHeader.label,     identifier: { role: COMPS_DATA.postHeader.role },     categoryId: CATEGORY_IDS.mainElements, index: 0, },
      { label: COMPS_DATA.postContent.label,     identifier: { role: COMPS_DATA.postContent.role },     categoryId: CATEGORY_IDS.mainElements, index: 1, },
      { label: COMPS_DATA.postFooter.label,     identifier: { role: COMPS_DATA.postFooter.role },     categoryId: CATEGORY_IDS.mainElements, index: 2, },
    ],
  });

const COMPS_DATA = {
  postHeader: { role: POST_PAGE_SELECTORS.POST_HEADER, label: 'Post Header' },
  postContent: {
    role: POST_PAGE_SELECTORS.POST_CONTENT,
    label: 'Post Content',
  },
  postFooter: { role: POST_PAGE_SELECTORS.POST_FOOTER, label: 'Post Footer' },
};

const CATEGORY_IDS = {
  mainElements: 'mainElements',
} as const;
